export const conversion = {
  headingKicker: 'Request a Demo',
  heading:
    'Shopmonkey is everything you need, no plugins or integrations required.',
  subhead: null,
  conversionPanelCallToAction: { url: '/demo', label: 'Request a Demo' },
  conversionPanelImage: {
    backgroundImage: {
      url:
        'https://www.datocms-assets.com/38431/1617401803-conversion-panel-image.png?auto=format',
    },
  },
  backgroundColor: { hex: 'var(--accent-color)' },
};
